export const ALL_CATEGORY_URL = 'api/categories';
export const ALL_PRODUCT_URL = 'api/products';
export const SINGLE_PRODUCT_URL = 'api/product';
export const ALL_BRAND_URL = 'api/brands';
export const ALL_SHOP_URL = 'api/shops';

export const SINGLE_SHOP_URL = 'api/shop';
export const SHOP_DATA = (slug) => `api/shop/${slug}/data`;
export const PRODUCT_BY_SHOP = (slug) => `api/shop/${slug}/product`;

export const CSRF_COOKIE_URL = 'sanctum/csrf-cookie';
export const LOGIN_URL = 'login';
export const REGISTRATION_URL = 'register';
export const LOGGED_IN_USER_URL = 'api/auth/me';
export const LOGOUT_URL = 'logout';
export const FORGOT_PASSWORD_URL = 'password/email';
export const RESET_PASSWORD_URL = 'password/reset';

export const USER_PROFILE_UPDATE_URL = 'api/user/update-profile';
export const USER_ADDRESS_CREATE_URL = 'api/user/address';
export const USER_ADDRESS_DELETE_URL = 'api/user/update-profile';
export const USER_ADDRESS_UPDATE_URL = 'api/user/address';
export const USER_ADDRESS_MAKE_DEFAULT = 'api/user/address/default';

export const ALL_ORDER_URL = 'api/user/order ';
export const SAVE_ORDER_URL = 'api/user/order';
export const PLACE_ORDER_URL = 'api/user/order';

export const SHOP_CREATE_REQUEST_URL = 'api/user/shop-create-request';
export const GET_USER_SHOPS_URL = 'api/user/shops';
export const CANCEL_SHOP_URL = 'api/user/cancel-shop';

export const GET_SETTINGS = 'api/app/setting';
export const GET_HOME_PAGE_CONTENT = 'api/home';
export const CARD_PAYMENT_TOKEN_URL = 'api/payment-token';
export const ORDER_RETURN_REQUEST = 'api/user/order/return-request';

// pages
export const GET_PAGES = 'api/pages';
export const GET_VENDORS = 'api/vendor';

export const GET_USER_RETURNED_ORDER_LIST = 'api/user/order-return';

// blog
export const BLOG_GET_URL = 'api/blog';
export const BLOG_GET_CATEGORIES_URL = 'api/blog/categories';

// Search
export const GET_PRODUCT_BY_SEARCH = 'api/search';


export const GET_SUPPORT_TICKET = 'api/user/support-ticket';
export const CREATE_SUPPORT_TICKET = 'api/user/support-ticket';
export const UPDATE_SUPPORT_TICKET = 'api/user/support-ticket';

export const SEND_MESSAGE = 'api/user/support-ticket/:id/send-message';
export const RESOLVE_TICKET = 'api/user/support-ticket';

export const GET_CONVERSATION = 'api/user/support-ticket';

export const SUBMIT_ORDER_ISSUE = 'api/user/order-issue';

export const CONTACT_US = 'api/contact-email';



export const LIVE_ADMIN_URL = 'https://admin.snow-hunt.co.uk';
