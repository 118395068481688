import { http } from '@/plugins/http';
import {
  ALL_BRAND_URL,
  ALL_CATEGORY_URL,
  ALL_PRODUCT_URL,
  ALL_SHOP_URL, CONTACT_US,
  CSRF_COOKIE_URL,
  GET_HOME_PAGE_CONTENT, GET_PAGES, GET_PRODUCT_BY_SEARCH,
  GET_SETTINGS, GET_VENDORS
} from '@/utils/api';
import { getApiResponse } from '@/utils/helper';

export const categories = () => getApiResponse(() => http.get(ALL_CATEGORY_URL));

export const products = (parameter = null) => {
  let url = parameter ? `${ALL_PRODUCT_URL}?${parameter}` : ALL_PRODUCT_URL;
  return getApiResponse(() => http.get(url));
};

export const brands = () => getApiResponse(() => http.get(ALL_BRAND_URL));

export const shops = (urlSearchParams) => getApiResponse(() => http.get(`${ALL_SHOP_URL}?${urlSearchParams}`));

export const getCookie = () => getApiResponse(() => http.get(CSRF_COOKIE_URL));

export const getAppSettings = () => getApiResponse(() => http.get(GET_SETTINGS));

export const getHomeContent = () => getApiResponse(() => http.get(GET_HOME_PAGE_CONTENT));

export const getPages = () => getApiResponse(() => http.get(GET_PAGES));

export const fetchVendors = ()=> getApiResponse(()=> http.get(GET_VENDORS));

export const getProductBySearch = (data)=> getApiResponse(()=> http.post(GET_PRODUCT_BY_SEARCH, data));

export const contactUs = (data) => getApiResponse(() => http.post(CONTACT_US, data));

