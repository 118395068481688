import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import routes from './routes';

import middlewareHandler from '@/middleware/middlewareHandler';

const { bootstrapApp, isBootstrapped } = middlewareHandler();


Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition
      ? savedPosition
      : { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
// Start Loading Spinner
  await NProgress.start();

  // Check direct hit or internal Hit
  if (!isBootstrapped.value) {
    // Direct hit load application initial Settings
    await bootstrapApp();
  }

  // if(to.name === from.name){
  //   return;
  // }

  await next();
});

router.afterEach(async () => {
  await NProgress.done();

});

export default router;
