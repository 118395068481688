export default [
  {
    title: 'Home',
    routeName: 'Home',
    link: '',
    icon: ''
  },
  {
    title: 'Products',
    routeName: 'Products',
    link: '',
    icon: ''
  },
  {
    title: 'ShopS',
    routeName: 'Vendors',
    link: '',
    icon: ''
  },
  {
    title: 'Blog',
    routeName: 'Blog',
    link: '',
    icon: ''
  },
];
