<script>
import HeaderNavigation from '@/layout/AppHeader';
import AppFooter from '@/layout/AppFooter';
import '@/assets/stylesheets/style.scss';
import headerNavigation from '@/utils/headerNavigation';
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: { AppFooter, HeaderNavigation },

  beforeRouteLeave(to, from, next) {
    this.loading = true;
    return next();
  },

  beforeRouteEnter(to, from, next) {
    this.loading = false;
    return next();
  },

  data: () => ({
    links: headerNavigation,
    drawer: false,
    fab: false,
    loading: false
  }),

  methods: {
    onScroll(e) {
      if (typeof window === 'undefined') return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },

    toTop() {
      this.$vuetify.goTo(0);
    }
  },

  computed: {
    ...mapGetters({
      navigationDrawer: 'app/navigationDrawer'
    }),

    overlay() {
      return this.$store.getters['app/loading'];
    }
  },
  watch: {
    navigationDrawer(val) {
      this.drawer = val;
    },

    drawer(val) {
      if (!val) {
        this.$store.dispatch('app/setNavigationDrawer', val);
      }
    }
  },
  mounted() {
    this.drawer = this.navigationDrawer;
  }
};
</script>

<template>
  <v-app id="inspire">
    <vue-snotify />

    <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>

    <RouterView />
  </v-app>
</template>

<style lang="scss">
.scale-slide-enter-active,
.scale-slide-leave-active {
  position: absolute;
  transition: all 0.85s ease;
}


.scale-slide-enter-from {
  left: -100%;
}


.scale-slide-enter-to {
  left: 0%;
}


.scale-slide-leave-from {
  transform: scale(1);
}


.scale-slide-leave-to {
  transform: scale(0.8);
}
</style>
