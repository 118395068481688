
const routeMap = {

  user: {

    profile: {
      path: 'profile',
      name: 'UserProfile',
      title: 'Profile',
      icon: 'mdi-folder',
    },

    support: {
      path: 'support-ticket',
      name: 'supportTicket',
      title: 'Support Ticket',
      icon: 'mdi-help-circle',
    },

    supportTicketDetails: {
      path: 'ticket-details/:ticket',
      name: 'ticketDetails',
      title: 'Ticket Details',
      icon: 'mdi-help-circle',
    },

    address: {
      path: 'address',
      name: 'UserAddress',
      title: 'Address',
      icon: 'mdi-star',
    },

    order: {
      path: 'orders',
      name: 'UserOrders',
      title: 'Orders',
      icon: 'mdi-account-multiple',
    },

    orderReturn: {
      path: 'order-return',
      name: 'UserOrderReturn',
      title: 'Return',
      icon: 'mdi mdi-autorenew',
    },

    track: {
      path: 'track',
      name: 'UserOrderTrack',
      title: 'Track Order',
      icon: 'mdi-history',
    },

    shops: {
      path: 'shop',
      name: 'UserShops',
      title: 'Create Your Shop',
      icon: 'mdi-store',
    },

    createShop: {
      path: 'create-shop',
      name: 'CreateShop',
      title: 'Open shop',
      icon: ''
    },

    orderSuccess: {
      path: '/order/success',
      name: 'OrderSuccess',
      title: 'Order Success',
      icon: ''
    },

    cartDetails: {
      path: '/cart-details',
      name: 'CartDetails',
      title: 'Cart Details',
      icon: ''
    },

    cart: {
      path: '/cart',
      name: 'Cart',
      title: 'Cart',
      icon: ''
    },

    invoice: {
      path: '/order/invoice/:invoice',
      name: 'Invoice',
      title: 'Invoice',
      icon: ''
    }
  },

  auth: {

    login: {
      path: '/auth/login',
      name: 'Login',
      title: 'Login',
      icon: '',
    },

    register: {
      path: '/auth/register',
      name: 'Register',
      title: 'Register',
      icon: '',
    },

    forgot: {
      path: '/auth/forgot',
      name: 'Forgot',
      title: 'Forgot',
      icon: '',
    },

    reset: {
      path: '/auth/reset-password/:token',
      name: 'Reset',
      title: 'Reset',
      icon: '',
    },

  },

  home: {
    path: '/',
    name: 'Home',
    title: 'Home',
    icon: '',
  },

  products: {
    path: '/products',
    name: 'Products',
    title: 'Products',
    icon: '',
  },

  product: {
    path: '/:vendor/product/:product/:variation',
    name: 'ProductSingle',
    title: 'Product Details',
    icon: '',
  },

  vendors: {
    path: '/vendors',
    name: 'Vendors',
    title: 'Vendors',
    icon: '',
  },

  vendor: {

    path: '/vendor/:slug',
    name: '',
    title: 'Vendor',
    icon: '',

    home: {
      path: '/',
      name: 'Vendor',
      title: 'Vendor',
      icon: '',
    },

    products: {
      path: 'products',
      name: 'VendorProducts',
      title: 'Vendor Products',
      icon: '',
    },

    featured: {
      path: 'featured',
      name: 'VendorFeatured',
      title: 'Vendor Products',
      icon: '',
    }
  },

  blog: {
    path: '/blog',
    name: 'Blog',
    title: 'Blog',
    icon: '',

    preview: {
      path: '/blog/:slug',
      name: 'BlogSingle',
      title: 'Blog',
      icon: '',
    },
  },

  pages: {

    about: {
      path: '/blog/:slug',
      name: 'Blog Single',
      title: 'Blog',
      icon: '',
    },

    privacyPolicy: {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      title: 'Privacy Policy',
      icon: '',
    },

    termsAndCondition: {
      path: '/terms-and-condition',
      name: 'TermsAndCondition',
      title: 'Terms And Condition',
      icon: '',
    },

    contact: {
      path: '/contact',
      name: 'Contact',
      title: 'Contact',
      icon: '',
    }
  }

};


export default routeMap;
