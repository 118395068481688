<template>
  <div
    class="search d-print-none"
    :style="{width : `${(windowWidth - 10)}px`}"
  >
    <v-autocomplete
      ref="searchInput"
      class="product-search"
      :clearable="true"
      hide-details
      flat
      return-object
      solo
      outlined
      full-width
      :items="items"
      :loading="isLoading"
      :search-input.sync="search"
      prepend-inner-icon="mdi-magnify"
      item-text="full_name"
      item-value="full_name"
      label="Search for product..."
      style="min-width: 100%"
      :no-data-text="noItemText"
      :allow-overflow="false"
      @click:clear="() => noResults = false"
      @keyup.enter="handleEnterClick"
    >
      <template #item="{ item }">
        <div
          class="d-flex w-100"
          @click="selectProduct(item)"
        >
          <v-list-item-avatar>
            <v-img :src="item.thumbnail || item.images[0]?.url" />
          </v-list-item-avatar>
          <v-list-item-content>
            {{ item.full_name }}
          </v-list-item-content>
        </div>
      </template>
      <template #append-outer>
        <v-btn
          class="global-search-btn"
          color="secondary"
          height="40"
          elevation="0"
          type="button"
          large
          @click.prevent="handleEnterClick"
        >
          <v-icon size="35">
            mdi-magnify
          </v-icon>
        </v-btn>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, toRefs, watch, ref } from 'vue';
import { debounce } from 'lodash';
import { getProductBySearch } from '@/api/api';
import { onStartTyping, useWindowSize } from '@vueuse/core';
import router from '@/router';
import store from '@/store';

export default defineComponent({
  name: 'ProductSearch',
  props: {
    small: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { root }) {

    const { width } = useWindowSize();

    const state = reactive({

      search: '',
      isLoading: false,
      items: [],
      model: null,
      noResults: false,
      windowWidth: width,

      noItemText: computed(()=> {

        if(!state.search){
          return 'Type to search';
        }

        if(state.search && state.search.length < 3){
          return 'Type to search';
        }

        return state.noResults
          ? 'No product found'
          : 'Type to search';
      })
    });

    const searchInput = ref(null);

    onStartTyping(()=> {
      if (!searchInput.value.active){
        searchInput.value.focus();
      }
    });

    const handleEnterClick = () => {

      if(!state.search){
        return;
      }

      const searchText = state.search.split(',')[0];

      const urlToGo = {
        name: 'Products',
        query: {
          search: encodeURI(searchText)
        }
      };

      state.noItemText = '';
      state.items = [];
      searchInput.value.blur();
      return router
        .push(urlToGo).then(()=> {
          // state.search = '';
        })
        .catch(()=>{})
      ;
    };

    const fetchResults = async () => {

      if(!state.search) {
        state.items = [];
        return;
      }

      if(state.search.length < 3){
        state.items = [];
        return;
      }

      state.isLoading = true;
      const response = await getProductBySearch({ search: state.search });
      state.items = response.data.data;
      state.noResults = !state.items.length;
      state.isLoading = false;

    };

    const setProduct = (payload) => {
      store.dispatch('product/setProduct', payload);
    };

    const selectProduct = (item) => {

      setProduct(item);

      const urlToGo = {
      name: 'ProductSingle',
        params: {
          variation : item.slug,
          product:item.product.slug,
          vendor:item.product.shop.slug
        }
      };

      return router
        .push(urlToGo)
        .catch(()=>{})
      ;
    };

    const debouncedFetchResults = debounce(fetchResults, 500);

    watch(() => state.search, () => {
      debouncedFetchResults();
    });

    return {
      ...toRefs(state),
      selectProduct,
      searchInput,
      handleEnterClick,
    };
  },

});
</script>

<style lang="scss">
.w-100 {
  width: 100%;
}
.v-list.v-select-list.v-sheet.theme--light.v-list--dense.theme--light {
  // max-width: 280px;
}
.search{
  max-width: 100%;
}
.global-search-btn {
    height: 56px !important;
    border-radius: 0 !important;
}
.v-input__append-outer {
  position: absolute;
  right: 0;
  margin: 0 !important;
}
.v-input__control {
  position: relative !important;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.product-search {
  .v-input__icon.v-input__icon--clear {
    position: absolute;
    right: 72px;
    top: 16px;
  }
}

</style>
