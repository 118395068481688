import state from '@/store/modules/common/state';
import getters from '@/store/modules/common/getters';
import mutations from '@/store/modules/common/mutations';
import actions from '@/store/modules/common/actions';
import { getHomeContent } from '@/api/api';
import collect from 'collect.js';

import { isMobile } from 'mobile-device-detect';

export default {

  namespaced: true,

  state: () => ({
    ...state,
  }),

  getters: {
    ...getters,
    categories: (state) => state?.data?.categories || [],
    categoryForMobileNav: (state) => state?.data?.category_with_children,

    brands: (state) => state?.data?.brands || [],

    vendors: (state) => state?.data?.vendors?.map((vendor) => ({
      name: vendor.name,
      logo: vendor?.logo?.url,
      slug: vendor.slug
    })) || [],

    products: (state) => state?.data?.products || [],

    mainSlider: (state) => {

      let sliders = collect(state?.data?.sliders);

      const slide = sliders.where('type', 'home_page_slide').first();

      const updatedSlide = {

        ...slide,
        items: slide?.items?.map((item) => ({
          ...item,
          image: (isMobile  &&  item?.mobile_image?.url) ? item?.mobile_image?.url : item?.image?.url,
        }))

      };
      return updatedSlide || [];
    },

    dealSlider: (state) => {

      let sliders = collect(state?.data?.sliders);

      const slide = sliders.where('type', 'deals_slides').first();

      const updatedSlide = {
        ...slide,
        items: slide?.items?.map((item) => ({
          ...item,
          image: item?.image?.url
        }))

      };
      return updatedSlide || [];
    },

    homeBanner: (state) => {

      let sliders = collect(state?.data?.sliders);

      const slide = sliders.where('type', 'home_banner').first();

      const updatedSlide = {
        ...slide,
        items: slide?.items?.map((item) => ({
          ...item,
          image: item?.image?.url
        }))

      };
      return updatedSlide || [];
    },

    popular_products: (state) => state?.data?.popular_products || [],
  },

  mutations: {
    ...mutations,
  },

  actions: {
    ...actions,

    async getData({ dispatch }) {
      dispatch('setLoading', true);

      try {
        const response = await getHomeContent();
        dispatch('setData', response.data.data);
      } catch (e) {
        dispatch('setErrors', e.errors || []);
        dispatch('setErrorMsg', e.message || '');
      }

      dispatch('setLoading', false);
    },
  }
};
