<template>
  <div class="sh-cart-badges">
    <v-badge
      :color="cartItemCount ? 'error' : 'transparent'"
      :content="cartItemCount"
      class="ml-1 text--white mr-3 mr-xl-0"
      overlap
    >
      <v-btn
        :disabled="!cartItemCount"
        :to="routeTo"
        depressed
        :color="cartItemCount ? 'secondary':''"
      >
        <v-icon> mdi-cart</v-icon>
      </v-btn>
    </v-badge>
  </div>
</template>

<script>
export default {
  name: 'CartButton',
  props: {

    cartItemCount: {
      type: Number,
      default: () => 0,
    },

    routeTo: {
      type: Object,
      default: () => {}
    }
  },
};
</script>

<style lang="scss" scoped>

.sh-cart-badges::v-deep .v-badge {
  .v-badge__wrapper{
    .v-badge__badge.primary {
      color: white !important;
    }
  }
}

</style>
<style lang="scss">
.v-badge__badge.error {
  //inset: auto calc(100% - 20px) calc(100% - 19px) !important;
  z-index: 1;
}
</style>
