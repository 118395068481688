<template>
  <div class="footer d-print-none">
    <!--top section-->
    <v-card
      class="pt-10 pb-10"
      flat
      tile
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            sm="3"
            md="4"
            order-sm="1"
            order-lg="1"
          >
            <v-img
              :src="require('@/assets/images/UK-Bangla-Marketplace-logo.png')"
              width="60%"
            />
            <v-card-text>
              A marketplace both for physical and digital goods.
            </v-card-text>
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="4"
            order-sm="2"
            order-lg="2"
          >
            <v-card-text class="text-uppercase title display-4 font-weight-bold pb-2 pt-2 pl-0 text-no-wrap">
              Useful Link
            </v-card-text>
            <v-divider class="pb-2" />
            <div class="link-container">
              <v-card-text
                v-for="(navigation, index) in state.footerUsefulLink"
                :key="'foot-nav ' + index"
                class="pb-1 pt-1 pl-0"
              >
                <router-link
                  class="bottom-link text-no-wrap"
                  :to="{name : navigation.routeName}"
                >
                  <v-icon class="mr-1">
                    mdi-chevron-right
                  </v-icon>
                  {{ navigation.name }}
                </router-link>
              </v-card-text>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            order-sm="2"
            order-lg="3"
          >
            <v-card-text class="text-uppercase title display-4 font-weight-bold pb-2 pt-2 pl-0">
              Contact Us
            </v-card-text>
            <v-divider class="pb-2" />
            <v-card-text class="pt-1 pb-1 bottom-link">
              <v-icon class="mr-2">
                mdi-home
              </v-icon>
              {{ $apps.address }}
            </v-card-text>
            <v-card-text class="pt-1 pb-1">
              <v-icon class="mr-2">
                mdi-phone
              </v-icon>
              <a
                class="bottom-link"
                :href="`tel:${$apps.phone}`"
              >
                {{ $apps.phone }}
              </a>
            </v-card-text>
            <v-card-text class="pt-1 pb-1 bottom-link">
              <v-icon class="mr-2">
                mdi-earth
              </v-icon>
              {{ $apps.web }}
            </v-card-text>
            <v-card-text class="pt-1 pb-1">
              <v-icon class="mr-2">
                mdi-email
              </v-icon>
              <a
                class="bottom-link"
                :href="`mailto:${$apps.email}`"
              >
                {{ $apps.email }}
              </a>
            </v-card-text>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-divider />

    <!--bottom section-->
    <v-card
      class="text-center dark"
      flat
      tile
    >
      <v-container>
        <v-card-text>
          <v-btn
            v-for="icon in state.socialLinks"
            :key="icon.name"
            class="mx-4"
            icon
          >
            <a
              :href="icon.link"
              target="_blank"
              rel="noreferrer"
            >
              <v-icon
                :color="icon.name.toLowerCase()"
                size="24px"
                class="social-icon"
              >
                {{ icon.icon }}
              </v-icon>
            </a>
          </v-btn>
        </v-card-text>

        <v-row>
          <v-card-text
            style="color: #fff"
            class=""
          >
            {{ new Date().getFullYear() }} All Rights Reserved @
            <router-link :to="{name : 'Home'}">
              <strong>{{ $apps.name }}.</strong>
            </router-link>
            Powered by
            <a
              class="font-weight-bold"
              href="https://snebtaf.co.uk"
              target="_blank"
            >
              Snebtaf Tech Solution Ltd.
            </a>
          </v-card-text>
          <v-spacer />
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import footerUsefulLink from '@/utils/footerUsefulLink';
import routeMap from '@/utils/routeMap';
import { defineComponent, reactive } from 'vue';
export default defineComponent({
  name: 'AppFooter',
  setup(){
    const state = reactive({
      socialLinks: [
        {
          icon: 'mdi-facebook',
          name: 'facebook',
          link: '#'
        }, {
          icon: 'mdi-twitter',
          name: 'twitter',
          link: '#',

        }, {
          icon: 'mdi-linkedin',
          name: 'linkedin',
          link: '#'
        }, {
          icon: 'mdi-instagram',
          name: 'instagram',
          link: '#'
        },
        // {
        //   icon: 'mdi-youtube',
        //   name: 'youtube',
        //   link: '#'
        // }
      ],
      footerUsefulLink: footerUsefulLink,
    });

    return {
      state,
      routeMap
    };
  },
});
</script>

<style lang="scss">

.footer {
  border-top: 1px solid var(--blue-100);
  margin-bottom: 76px; // mobile bottom nav bar height

  margin-top: auto;
}

.v-btn--absolute.v-btn--bottom, .v-btn--fixed.v-btn--bottom {
  bottom: 95px !important;
}

@media screen and (min-width: 1400px) {
  .footer {
    margin-bottom: 0;
  }
  .v-btn--absolute.v-btn--bottom, .v-btn--fixed.v-btn--bottom {
    bottom: 16px !important;
  }
}

.bottom-link {
  cursor: pointer;
  color: #012169 !important;
  transition: ease-in .02s;
}

.bottom-link:hover {
  color: #931a1a !important;
}

.social-icon {
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.75;
  }
}
</style>
