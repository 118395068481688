import state from '@/store/modules/common/state';
import getters from '@/store/modules/common/getters';
import mutations from '@/store/modules/common/mutations';
import actions from '@/store/modules/common/actions';
import { shops } from '@/api/api';
import { shopData, shopProducts, singleShop } from '@/api/shop';
import {
  PRODUCT_SORT_BEST_SELLING,
  PRODUCT_SORT_NEW,
  PRODUCT_SORT_OLD,
  PRODUCT_SORT_POPULAR,
  PRODUCT_SORT_PRICE_HIGH,
  PRODUCT_SORT_PRICE_LOW
} from '@/store/modules/products/productTypes';
import { isEmpty } from 'lodash';

export default {
  namespaced: true,

  state: () => ({
    ...state,
    data: {
      all: [],
      total: 0
    },

    currentVendor: {},

    currentVendorData: {

      categories: [],
      brands: [],

      priceRange: {
        isDefault: true,
        min: 0,
        max: 0,
      }

    },
    vendorBasicSortOption: [

      {
        name: 'Price Low To High',
        value: PRODUCT_SORT_PRICE_LOW
      },
      {
        name: 'Price High To Low',
        value: PRODUCT_SORT_PRICE_HIGH
      },
      {
        name: 'Oldest',
        value: PRODUCT_SORT_OLD
      },
      {
        name: 'Newest',
        value: PRODUCT_SORT_NEW
      },
      {
        name: 'Best Selling',
        value: PRODUCT_SORT_BEST_SELLING
      }

    ],

    vendorProducts: [],

    paginationLength: 0,

    filters: {

      basic: {
        sort_by: 'date', // [date, price],
        sort_high: 1, // [0, 1]
      },

      category: null,
      brand: null,

      searchString: '',
      page: 1,

      priceRange: {
        min: 0,
        max: 0,
      },

    },
  }),

  getters: {
    ...getters,

    data: (state) => state.data.all,

    hasData: (state) => !isEmpty(state?.data.all),

    currentVendor: (state) => state.currentVendor,

    vendorCategories: (state) => state.currentVendorData?.categories || [],

    vendorBrands: (state) => state.currentVendorData?.brands || [],

    vendorPriceRange: (state) => state.currentVendorData?.priceRange,

    basicSortOption: (state) => state.vendorBasicSortOption,

    vendorProducts: (state) => state.vendorProducts || [],

    paginationLength: (state) => state.paginationLength || 1,

    productsCount: (state) => state?.currentVendor?.productsCount || 0,


    slide: (state) => {

      const slideItems = state.currentVendor?.slide?.items || [];

      const slideItem = slideItems.map((slideItem) => ({
        action_link: slideItem?.action_link,
        action_text: slideItem?.action_text,
        caption: slideItem?.caption,
        title: slideItem?.title,
        image: slideItem?.image?.url,
        mobile_image: slideItem?.mobile_image?.url
      }));

      return slideItem;
    }
  },

  mutations: {
    ...mutations,

    updateData: (state, payload) => state.data = payload,

    updateCurrentVendor: (state, payload) => state.currentVendor = payload,

    updateCurrentVendorData: (state, payload) => state.currentVendorData = payload,

    updateVendorProducts: (state, payload) => state.vendorProducts = payload,

    updatePaginationLength: (state, payload) => state.paginationLength = payload,

    updateFilters: (state, payload) => state.filters = payload
  },

  actions: {
    ...actions,

    setCurrentVendor: ({ commit }, payload) => commit('updateCurrentVendor', payload),

    setCurrentVendorData: ({ commit }, payload) => commit('updateCurrentVendorData', payload),

    setVendorProducts: ({ commit }, payload) => commit('updateVendorProducts', payload),

    async getCurrentVendor({ dispatch }, vendor) {

      dispatch('setLoading', true);

      let response = await singleShop(vendor);

      const data = {
        ...response?.data?.data?.shop,
        products: response.data.data.products.data || [],
        logo: response?.data?.data?.shop?.logo?.url,
        banner: response?.data?.data?.shop?.banner?.url,
      };

      dispatch('setCurrentVendor', data);
      dispatch('getCurrentVendorData', vendor);
      dispatch('setLoading', false);
    },

    async getCurrentVendorData({ dispatch, state }, vendor) {

      const response = await shopData(vendor);

      let priceRange = {
        min: 0,
        max: 0,
        isDefault: true
      };


      if(response?.data?.data?.price_range?.max){

        if(state.currentVendorData.priceRange.isDefault) {

          priceRange = {
            min: response?.data?.data?.price_range?.min,
            max: response?.data?.data?.price_range?.max,
            isDefault: false
          };

        }

      }

      let data = {
        categories: [...response?.data?.data?.categories || []],
        brands: [...response?.data?.data?.brands || []],
        priceRange: priceRange
      };

      dispatch('setCurrentVendorData', data);

    },

    async getVendorProducts({ dispatch, commit, state }, slug) {
      const appliedFilters = { ...state.filters };

      let parameter = new URLSearchParams();

      for (let filterKey in appliedFilters) {

        const filterValue = appliedFilters[filterKey];

        switch (filterKey) {

          case 'basic':
            if (!filterValue) break;
            parameter.append('sort_by', filterValue.sort_by);
            parameter.append('sort_high', filterValue.sort_high);
            break;

          case 'category':
            if (!filterValue) break;
            parameter.append(filterKey, filterValue);
          break;

          case 'brand':
            if (!filterValue) break;
            parameter.append(filterKey, filterValue);
          break;

          case 'searchString':
            if (!filterValue) break;
            parameter.append('search', filterValue);
          break;

          case 'priceRange':
            if (filterValue.min === state.currentVendorData?.priceRange?.min && filterValue.max === state?.currentVendorData?.priceRange?.max) {
              break;
            }
            parameter.append('min_price', filterValue?.min);
            parameter.append('max_price', filterValue?.max);

            break;

          case 'page':
            if (!filterValue || filterValue <= 1) break;
            parameter.append('page', filterValue);
            break;

        }
      }

      const response = await shopProducts(slug, parameter);

      dispatch('setVendorProducts', response?.data?.data?.data);
      dispatch('getCurrentVendorData', slug);

      commit('updatePaginationLength', response.data.data.last_page);

    },

    applyFilter({ commit, state }, { type, data }) {


      const filters = { ...state.filters };

      const value = (isEmpty(data) && !data) ? null : data;

      for (let filterKey in filters) {

        if (filterKey === type) {

          filters[filterKey] = value;

        }

      }

      commit('updateFilters', filters);
    },

    async getVendorData({ dispatch, commit }, { searchText = '', pageIndex = '1' }) {

      dispatch('setLoading', true);

      const params = new URLSearchParams();
      params.append('search', searchText);
      params.append('page', pageIndex);

      let vendorList = await shops(params);
      commit('updateData', {
        total: vendorList.data.data.last_page,
        all: vendorList.data.data.data
      });

      dispatch('setLoading', false);
    },
  }

};
