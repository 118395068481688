import { BASE_URL } from '@/config/api';
import axios from 'axios';
// import Vue from 'vue';
// import store from '@/store/index'
// import {storeHelper} from "@/utils/helper";
// import {APP_LOADING, appNameSpace} from "@/store/modules/app/appTypes";

let http = axios.create({
  baseURL: BASE_URL,
  // responseType: 'json',

  withCredentials: true,
  headers: {
    'Accept': 'application/json',
  }
});

http.interceptors.request.use((config) => {

  // if not form data then add content-type JSON header
  if (!(config.data instanceof FormData)) {
    config.headers['Content-Type'] = 'application/json';
  }

  return config;
});

// http.interceptors.request.use(async (config) => {
//   // Start Loading Overlay From App
//     await store.dispatch(storeHelper(appNameSpace, APP_LOADING), true)
//     return config
//
// }, async (config)=>{
//   await store.dispatch(storeHelper(appNameSpace, APP_LOADING), false)
//   return config
// });

// http.interceptors.response.use(async (data) => {
//   // Stop Loading Overlay From App
//   await store.dispatch(storeHelper(appNameSpace, APP_LOADING), false)
//   return data
//
// }, async (config)=>{
//   await store.dispatch(storeHelper(appNameSpace, APP_LOADING), false)
//   return config
// });

//Vue.prototype.$http = http;
export { http } ;
