import Vue from 'vue';
import appConfig from '@/config/app';

export const BASE_ADMIN_URL = process.env.VUE_APP_ADMIN_BASE_URL;

const apps = {

  name: 'UK Bangla Marketplace', // Application Name
  logo: require('@/assets/images/logo_with_tagline.png'), // Application Logo
  web: 'ukbanglamarketplace.com',
  address: 'London, UK',
  phone: '+44 777 888 999',
  email: 'info@ukbanglamarketplace.com',

  poweredBy: 'Snebtaf',
  poweredByLink: 'https://snebtaf.co.uk',
  privacyPolicyLink: '#',
  termAndConditionLink: '#',

  ...appConfig
};

Vue.prototype.$apps = apps;
export default apps;
