import Auth from '@/api/Auth';
import { getAppSettings } from '@/api/api';
import state from '@/store/modules/common/state';
import getters from '@/store/modules/common/getters';
import mutations from '@/store/modules/common/mutations';
import actions from '@/store/modules/common/actions';

export default {
  namespaced: true,

  state: () => ({
    ...state,
    navigationDrawer: false,
    categoryNavigationDrawer: false,
    isLoggedIn: false,
    isBooted: false,
    appLoaded: false,
    tokenValidated: false,
    settings: {},
    topCategories: [],
  }),

  getters: {
    ...getters,
    navigationDrawer: (state) => state.navigationDrawer,
    categoryNavigationDrawer: (state) => state.categoryNavigationDrawer,
    isLoggedIn: (state) => state.isLoggedIn,
    isGuest: (state) => !state.isLoggedIn,
    isBooted: (state) => state.isBooted,
    appLoaded: (state) => state.appLoaded,
    tokenValidated: (state) => state.tokenValidated,
    settings: (state) => state.settings,
    topCategories: (state) => state.topCategories,
  },

  mutations: {
    ...mutations,
    updateIsLoggedIn: (state, payload) => state.isLoggedIn = payload,
    updateIsBooted: (state, payload) => state.isBooted = payload,
    updateAppLoaded: (state, payload) => state.appLoaded = payload,
    updateTokenValidated: (state, payload) => state.tokenValidated = payload,
    updateSettings: (state, payload) => state.settings = payload,
    updatedNavigationDrawer: (state, payload) => state.navigationDrawer = payload,
    updatedCategoryNavigationDrawer: (state, payload) => state.categoryNavigationDrawer = payload,
    updateCategories: (state, payload) => state.topCategories = payload
  },

  actions: {
    ...actions,

    validateToken: async ({ state, dispatch, commit }) => {

      if(state.isBooted) {
        return;
      }

      await dispatch('setLoading', true); // Set application to loading state

      await dispatch('fetchLoggedInUser'); // fetch user
      await dispatch('fetchSettings'); // get settings
      await dispatch('pages/getPages', {}, { root: true }); // get pages
      await dispatch('home/getData', {}, {root: true});

      commit('updateTokenValidated', true);
      commit('updateIsBooted', true);


      await dispatch('setLoading', false); // set loading false
    },

    tryLogout: async ({ dispatch, commit }) =>  {

      await Auth.logout();
      await dispatch('user/setData', {}, {root: true});
      await commit('updateIsLoggedIn', false);

    },

    tryLogin: async ({ dispatch }, payload) =>  {
      try {

        await Auth.login(payload);
        const response = await dispatch('fetchLoggedInUser');
        return response;

      }catch(e) {

        dispatch('setErrors', e?.errors);
        dispatch('setError_msg', e?.message);
        return Promise.reject(e);

      }
    },

    fetchLoggedInUser: async ({ commit, dispatch }) => {

      const response = await Auth.check();

      if(!response.data.status){
        commit('updateIsLoggedIn', false);
        return;
      }

      dispatch('user/setData', response?.data?.data?.user, { root: true });
      commit('updateIsLoggedIn', true);

    },

    fetchSettings: ({ commit, dispatch }) => {
      dispatch('handleAsyncAction', {
        handler: async ()=> {
          const response = await getAppSettings();
          commit('updateSettings', response.data.data.settings);
          // Removed Because core updated
          // commit('updateCategories', response.data.data.categories);
        }
      });
    },

    setNavigationDrawer({commit}, payload){
      commit('updatedNavigationDrawer', payload);
    },
    setCategoryNavigationDrawer({commit}, payload){
      commit('updatedCategoryNavigationDrawer', payload);
    }
  }
};
