import { dateFormatter, moneyFormatter } from '@/utils/constants';
import appConstants from '@/utils/appConstants';

export const appMixin = {
  filters: {
    strLimit(value, size, suffix='...') {
      if (!value) return '';

      value = value.toString();
      if (value.length <= size) return value;

      return value.substr(0, size) + suffix;
    },
    currency(val) {
      return moneyFormatter(val);
    },

    price(val) {
      return appConstants.currencySign + val;
    },

    date(date) {
      return dateFormatter(date);
    },

    toNumber(number) {
      if (!isNaN(parseInt(number))) {
        return parseInt(number);
      }
      return number;
    },

    avatar(val) {
      return val[0].toUpperCase();
    }
  },
  methods: {
    statusBadge(value) {

      if (!value) return;

      let color = 'info';

      switch (value) {

        case 'initiated':
          color = 'info';
          break;

        case 'pending':
          color = 'warning';
        break;

        case 'close':
          color = 'primary';
        break;

        case 'processing':
        case 'shipping':
        case 'open':
          color = 'secondary';
        break;

        case 'delivered':
        case 'paid':
        case 'active':
        case 'resolved':
        case 'accepted':
          color = 'success';
        break;

        case 'failed':
        case 'cancelled':
        case 'unpaid':
        case 'rejected':
          color = 'error';
        break;
      }

      return `<span class="ma-2 v-chip theme--light v-size--default ${color} white--text">
          <span class="v-chip__content"> ${value.toUpperCase()} </span>
      </span>`;
    },
    slugify(str) {
      return str.toString().normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase().trim().replace(/\s+/g, '-')
        .replace(/[^\w-]+/g, '').replace(/--+/g, '-');
    },

    backToPreviousPage(){
      return this.$router.back();
    },

    gotoTop(){
      this.$vuetify.goTo('body',{
        duration: 900,
        offset: 0,
        easing: 'easeInOutCubic',
      });
    }
  }
};
