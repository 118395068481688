import state from '@/store/modules/common/state';
import getters from '@/store/modules/common/getters';
import mutations from '@/store/modules/common/mutations';
import actions from '@/store/modules/common/actions';
import { getPages } from '@/api/api';
import { isEmpty } from 'lodash';
import { apiUrlHelper } from '@/utils/helper';

export default {
  namespaced: true,

  state: () => ({
    ...state,

  }),

  getters: {
    ...getters,

    getPageSection: (state) => {

      return (pageSlug, section, enableDisableKey) => {

        const allPages = [...state.data];

        let data = {
          section: null,
          sectionEnabled: false,
          settings: [],
        };

        if(isEmpty(allPages)) return data;


        const page = allPages.find((page) => page.slug === pageSlug);

        if(isEmpty(page)) return data;

        let settings = page?.settings?.filter((setting) => setting.section === section) || [];

        data = {
          section: section,
          sectionEnabled: settings.find((setting) => setting.key === enableDisableKey)[enableDisableKey] || false,
          settings: settings.filter((item) => item?.key !== enableDisableKey).map((item) => {
            return {
              ...item,
              value: item?.value_type === 'image' ? apiUrlHelper('setting', item?.value) : item?.value
            };
          }),
        };


        return data;
      };
    }
  },

  mutations: {
    ...mutations,
  },

  actions: {
    ...actions,

    async getPages({ dispatch }) {

      dispatch('setLoading', true);

      const response = await getPages();

      dispatch('setData', response.data.data);

      dispatch('setLoading', false);
    }
  },
};

