import Store from '@/store/index';
import collect from 'collect.js';


let appSetting = {
  currencySign: '£', // Currency Symbol For App `$ || £`
  currency: 'GBP', // currency Name `GBP || USD`
  locale: 'en-GB', // BCP 47 language tag

  // Documentation
  // https://flaviocopes.com/how-to-format-number-as-currency-javascript/

  dateFormat: 'DD-MMM-YYYY' // 01-jan-1990
  // documentation @ https://day.js.org/docs/en/display/format
};

let stripePublishableKey = null;
let isStripeEnabled = false;

Store.subscribe((mutation) => {

  if (mutation.type === 'app/updateSettings') {

    let setting = Store.getters['app/settings'];
    let collection = collect(setting);

    if (collection.isEmpty()) {
      return;
    }

    appSetting = {
      currencySign: collection.where('key', 'locale').first()?.value || '£',
      currency: collection.where('key', 'currency').first()?.value || 'GBP',
      locale: collection.where('key', 'currency').first()?.value || 'en-GB',
      dateFormat: collection.where('key', 'date_format').first()?.value || 'DD-MMM-YYYY',
    };

    const isStripeInTestMode = collection.where('key', 'stripe_test_mode').first()?.value || false;
    isStripeEnabled = Boolean(parseInt(collection.where('key', 'enable_stripe_payment').first()?.value || 0));

    stripePublishableKey = isStripeInTestMode
      ? collection.where('key', 'stripe_test_pub').first()?.value || null
      : collection.where('key', 'stripe_live_pub').first()?.value || null
    ;
  }
});

export default appSetting;
const googleAnalyticsKey = process.env.VUE_APP_GOOGLE_ANALYTICS;
export { googleAnalyticsKey, stripePublishableKey, isStripeEnabled };
