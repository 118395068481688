import userRoutes from '@/router/userRoutes';
import authRoute from '@/router/authRoute';
import routeMap from '@/utils/routeMap';

const routes = [
  ...authRoute,

  {
    path: '/',
    component: () => import('@/layout/MainLayout'),
    children: [
      ...userRoutes,

      {
        path: routeMap.home.path,
        name: routeMap.home.name,
        component: () => import('@/views/Home/Home')
      },

      {
        path: routeMap.products.path,
        name: routeMap.products.name,
        component: () => import('@/views/Products')
      },

      {
        path: routeMap.product.path,
        name: routeMap.product.name,
        component: () => import('@/views/Products/ProductsDetails')
      },

      {
        path: routeMap.vendors.path,
        name: routeMap.vendors.name,
        component: () => import('@/views/vendor/Vendors')
      },

      {
        path: routeMap.vendor.path,
        component: () => import('@/views/vendor/Vendor'),
        children: [
          {
            path: routeMap.vendor.home.path,
            name: routeMap.vendor.home.name,
            component: () => import('@/views/vendor/About')
          },
          {
            path: routeMap.vendor.products.path,
            name: routeMap.vendor.products.name,
            component: () => import('@/views/vendor/AllProducts')
          },
          {
            path: routeMap.vendor.featured.path,
            name: routeMap.vendor.featured.name,
            component: () => import('@/views/vendor/FeaturedProducts')
          }
        ]
      },

      {
        path: routeMap.blog.path,
        name: routeMap.blog.name,
        component: () => import('@/views/Blog/Blogs')
      },

      {
        path: routeMap.blog.preview.path,
        name: routeMap.blog.preview.name,
        component: () => import('@/views/Blog/BlogDetails')
      },

      {
        path: routeMap.pages.about.path,
        name: routeMap.pages.about.name,
        component: () => import('../views/About')
      },

      {
        path: routeMap.pages.privacyPolicy.path,
        name: routeMap.pages.privacyPolicy.name,
        component: () => import('@/views/Pages/PrivacyPolicy')
      },

      {
        path: routeMap.pages.termsAndCondition.path,
        name: routeMap.pages.termsAndCondition.name,
        component: () => import('@/views/Pages/TermsAndCondition')
      },

      {
        path: routeMap.pages.contact.path,
        name: routeMap.pages.contact.name,
        component: () => import('@/views/Pages/Contact')
      },
      {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/Pages/NotFound')
      }
    ]
  },
  {
    path: '/manage',
    component: () => import('@/views/Admin/Admin.js'),
    name: 'Admin'
  },
];

export default routes;
